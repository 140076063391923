<template>
  <div class="home">
    <wordlegame />
  </div>  
</template>

<script>
import Wordlegame from '../components/Wordlegame.vue'

export default {
  components: { Wordlegame },
  setup() {
  }
}
</script>

<style>

</style>

