import { ref } from 'vue'

// firebase imports
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { GA } from '../firebase/config'
import { setUserId } from 'firebase/analytics' 

//refs
const user = ref(auth.currentUser)

//auth changes
onAuthStateChanged(auth, (_user) => {
    user.value = _user
    if(_user) {
        setUserId(GA, _user.uid)
    }
})

const getUser = () => {
    return { user  }
}

export default getUser