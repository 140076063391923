import { initializeApp } from "firebase/app"
import { getFirestore, Timestamp } from "firebase/firestore"
import { getAuth } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics"

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCzGAGortOPA3zR6XYqDeAZ9k6fnDgNaQ8",
    authDomain: "wordle-cosmic1.firebaseapp.com",
    projectId: "wordle-cosmic1",
    storageBucket: "wordle-cosmic1.appspot.com",
    messagingSenderId: "527972256893",
    appId: "1:527972256893:web:601797e6f346f78f1ff514",
    measurementId: "G-VCKH2GV1PT"
};

// Initialize Firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()
const dbTypeTimeStamp = Timestamp

const GA = getAnalytics()

export { db , auth, dbTypeTimeStamp, GA }