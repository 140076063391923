<template>
    <div class="d-flex justify-content-center">
        <div class="navbar px-3">
        <div>
            <span class="h5">Wordle</span>
        </div>
        <div>
            {{elapsedTime.human}}
        </div>
        <div class="float-right">
            <a @click="changeColorMode()" style="cursor: pointer;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high" viewBox="0 0 16 16">
                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill" viewBox="0 0 16 16">
                <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                </svg>                
            </a>
            &nbsp;
            <a @click="displayHelp()" style="cursor: pointer;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-octagon" viewBox="0 0 16 16">
                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>                
            </a>
            &nbsp;
            <a @click="restartGame()" v-if="!welcomeMode" style="cursor: pointer;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon" viewBox="0 0 16 16">
                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </a>
        </div>
        </div>
    </div>

    <div class="container board">
        <ul class="row row-cols-5 list-unstyled list mb-1 g-1">
            <li class="col my-0" v-for="tile in boardTiles" :key="'tile-'+tile.row+'-'+tile.col">
                <span class="d-block text-decoration-none tile">
                    <div :class="'p-1 py-1 mb-1 text-center tile-'+tile.status">
                        <a class="text-white" v-if="tile.url" target="_blank" :href="tile.url">
                            {{tile.value?tile.value:'&nbsp;'}}
                        </a>
                        <span v-else>
                            {{tile.value?tile.value:'&nbsp;'}}
                        </span>
                    </div>
                </span>
            </li>  
        </ul>
    </div>

    <div class="container keyboard">
        <div v-if="showHelpFlag" class="alert alert-info" role="alert">
            <p v-if="helpTip==1">Your goal is to find the 5-letter word chosen by the game.</p>
            <p v-if="helpTip==2">You have 6 attempts to find the word.</p>
            <p v-if="helpTip==3">In each attempt, enter a 5-letter word and tap "GO".</p>
            <div v-if="helpTip==4">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="p-2 py-2 text-center rounded key-correct">A</div>
                        </div>
                        <div class="col">
                            <div class="p-2 py-2 text-center rounded key-misplaced">E</div>
                        </div>
                        <div class="col">
                            <div class="p-2 py-2 text-center rounded key-invalid">U</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <small>Valid letter, <br />
                            Correct position</small>
                        </div>
                        <div class="col text-center">
                            <small>Valid letter, <br />
                            Wrong position</small>
                        </div>
                        <div class="col text-center">
                            <small>No match</small>
                        </div>
                    </div>
                </div>
            </div>
            <p v-if="helpTip==5">When all 5-letters turn green, you have found the right word.</p>
            <p v-if="helpTip==6">That's all there is to it.<br />Good luck!<br />Press "Close" to play the game.</p>
            <hr />
            <div class="container">
                <div class="row">
                    <div class="col" v-if="helpTip>1">
                        <button type="button" class="btn btn-secondary" @click="helpTip=helpTip-1">Back</button>
                    </div>
                    <div class="col">
                        <button type="button" class="btn btn-dark" @click="showHelpFlag=false">Close</button>
                    </div>
                    <div class="col" v-if="helpTip<6">
                        <button type="button" class="btn btn-primary" @click="helpTip=helpTip+1">Next</button>
                    </div>
                </div>
            </div>
        </div>              
        <div v-else-if="showSettings" class="alert alert-primary" role="alert">
            <p>Word complexity level</p>
            <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="settingGameLevel" id="levelEasy" value="easy" v-model="currentDifficultyLevel">
            <label class="form-check-label" for="levelEasy">Easy</label>
            </div>
            <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="settingGameLevel" id="levelMedium" value="medium" v-model="currentDifficultyLevel">
            <label class="form-check-label" for="levelMedium">Medium</label>
            </div>
            <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="settingGameLevel" id="levelDifficult" value="difficult" v-model="currentDifficultyLevel">
            <label class="form-check-label" for="levelDifficult">Difficult</label>
            </div>
            <hr />
            <div class="container">
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn-primary" @click="showSettings=false;restartGame()">Apply & Start new Game</button>
                    </div>
                </div>
            </div>
        </div>        
        <div v-else-if="welcomeMode" class="alert alert-primary" role="alert">
            <p>
            Welcome to Wordle! <br />
            Tap <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-octagon" viewBox="0 0 16 16">
                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>  icon on the top to learn more. <br />
            Ready for the challenge?
            </p>
            <hr />
            <div class="d-flex justify-content-left">
                    <div class="px-2 gx-2">
                        <button type="button" class="btn btn-primary" @click="restartGame()">Play now</button> 
                    </div>
                    <div class="px-4 py-1 gx-2">
                        <a @click="showSettings=true" style="cursor: pointer;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                            </svg>                
                        </a>
                    </div>
            </div>
        </div>        
        <div v-else-if="restartConfirm" class="alert alert-success" role="alert">
            <p>
            Do you wish to restart a new game?
            </p>
            <hr />
            <div class="container">
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn-secondary" @click="restartConfirm=false">No, go back</button>
                    </div>
                    <div class="col">
                        <button type="button" class="btn btn-primary" @click="restartGame()">Yes</button>
                    </div>
                </div>
            </div>
        </div>        
        <div v-else-if="boardSolved" class="alert alert-success" role="alert">
            <h4 class="alert-heading">Congratulations! </h4>
            <p>
            You found the word in&nbsp;{{cursor.y+1}}&nbsp;attempt(s). <br />
            Challenge completed in&nbsp;{{elapsedTime.human}}
            </p>
            <hr />
            <div class="container">
                <div class="row">
                    <div class="col-8">
                        <button type="button" class="btn btn-primary" @click="restartGame()">Start a new game</button> 
                    </div>
                    <div class="col-4">
                        <button type="button" v-if="browserSupportshare" @click="handleShare()" class="btn btn-outline-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z"></path>
                        <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z"></path>
                        </svg>
                        </button>
                    </div>
                </div>
            </div>            
        </div>        
        <div v-else-if="gameOver" class="alert alert-warning" role="alert">
            <h4 class="alert-heading">Game over! </h4>
            <p>
            You've ran out of attempts. Answer: <span class="p-1 npm run serve
            bg-success text-white text-uppercase"><a :href="'https://www.google.com/search?q=define:'+solution" target="_blank" style="text-decoration: none;" class="text-white">{{solution}}</a></span> <br />
            Better luck next time.
            </p>
            <hr />
            <p class="mb-0"> <button type="button" class="btn btn-primary" @click="restartGame()">Start a new game</button> </p>
        </div>  
        <ul v-else v-for="kbline in keyboard" :key="kbline" class="row list-unstyled list mb-2 g-2">
            <li v-for="ky in kbline" :key="'keyb-key-'+ky.text" class="col">
                <a class="d-block text-decoration-none" @click="handleKey(ky.text)">
                    <div v-if="ky.text=='BACKSPACE'" :class="'p-2 py-2 text-center rounded key-' + ky.status" :id="'keyboard-key-data-'+ky.text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                            <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"/>
                            <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z"/>
                            </svg>
                    </div>    
                    <div v-else :class="'p-2 py-2 text-center rounded key-' + ky.status" :id="'keyboard-key-data-'+ky.text">
                        {{ky.text}}
                    </div>
                </a>
            </li>
        </ul>
    </div>

    <canvas id="resultImage" style="display: none;"></canvas>
</template>

<script>
import { ref, onMounted } from 'vue'
import { collection, query, where, limit, getDocs, orderBy, addDoc, updateDoc } from 'firebase/firestore'
import { db, dbTypeTimeStamp, GA } from '../firebase/config'
import { logEvent, setUserId } from "firebase/analytics";
import getUser from '../composables/getUser'
export default {
    setup() {
        const boardTiles = ref([])
        const boardSolved = ref(false)
        const welcomeMode = ref(true)
        const gameOver = ref(false)
        const showHelpFlag = ref(false)
        const browserSupportshare = ref(false)
        const restartConfirm = ref(false)
        const elapsedTime = ref({ms: 0, human: '00:00'})
        const gameStart = ref(null)
        const helpTip = ref(1)
        const showSettings = ref(null)
        const cursor = ref({x:0,y:0})
        const processingKey = ref(false)
        const session = ref({id:null, docSessionRef: null})
        const { user } = getUser()
        const currentDifficultyLevel = ref('easy')
        const collectionWordSource = ref(null)
        const keyboard = ref([
                                [{text: 'Q',status: 'unknown'},{text: 'W',status: 'unknown'},{text: 'E',status: 'unknown'},{text: 'R',status: 'unknown'},{text: 'T',status: 'unknown'},{text: 'Y',status: 'unknown'},{text: 'U',status: 'unknown'},{text: 'I',status: 'unknown'},{text: 'O',status: 'unknown'},{text: 'P',status: 'unknown'}], 
                                [{text: 'A',status: 'unknown'},{text: 'S',status: 'unknown'},{text: 'D',status: 'unknown'},{text: 'F',status: 'unknown'},{text: 'G',status: 'unknown'},{text: 'H',status: 'unknown'},{text: 'J',status: 'unknown'},{text: 'K',status: 'unknown'},{text: 'L',status: 'unknown'}], 
                                [{text: 'GO',status: 'unknown bg-primary text-white'},{text: 'Z',status: 'unknown'},{text: 'X',status: 'unknown'},{text: 'C',status: 'unknown'},{text: 'V',status: 'unknown'},{text: 'B',status: 'unknown'},{text: 'N',status: 'unknown'},{text: 'M',status: 'unknown'},{text: 'BACKSPACE',status: 'unknown'}]
                            ])
        const solution = ref(null)
        let sourceWords = 'words_dict'
        const wordsRef = collection(db, sourceWords)
        const loadRandomWord = async () => {
            logEvent(GA, 'loadRandomWord', {})
            if(currentDifficultyLevel.value == 'medium') {
                collectionWordSource.value = (Math.random()>=0.5?'words_dict_nonisogram_highfreq':'words_dict_isogram_mediumfreq')
            }
            else if(currentDifficultyLevel.value == 'difficult') {
                collectionWordSource.value = (Math.random()>=0.66?'words_dict_nonisogram_lowfreq':(Math.random()>=0.5?'words_dict_isogram_lowfreq':'words_dict_nonisogram_mediumfreq'))
            }
            else {
                collectionWordSource.value = 'words_dict_isogram_highfreq'
            }
            let challengeRef = collection(db, collectionWordSource.value)
            /* let randfreqs = 
                    [
                        //((Math.floor(Math.random() * (200 - 100) + 100)) / 100),
                        //(Math.floor(Math.random() * (500 - 200) + 200)) / 100,
                        (Math.floor(Math.random() * (2000 - 500) + 500)) / 100,
                        (Math.floor(Math.random() * (2000 - 500) + 500)) / 100,
                        (Math.floor(Math.random() * (2000 - 500) + 500)) / 100,
                        (Math.floor(Math.random() * (100000 - 2000) + 2000)) / 100
                    ]
            let randfreq = (Math.floor(Math.random()*1000)+1)
            try { randfreq = randfreqs[ Math.floor(Math.random() * (randfreqs.length - 0) + 0) ] } catch {}            
            const queryRandomWord = query(challengeRef, where('freq', '>=', randfreq ), limit(1)) */
            const randField = 'random.rand' + (Math.floor(Math.random() * (3 - 1 + 1) + 1)).toString()
            const queryRandomWord = query(challengeRef, where(randField, '>=', Math.random() ), limit(1))
            let docWordObj = null
            try {
                const docRandWordSnap = await getDocs(queryRandomWord)
                if(docRandWordSnap.docs.length) {
                    docWordObj = { ...docRandWordSnap.docs[0].data(), id: docRandWordSnap.docs[0].id }
                    solution.value = docWordObj.word
                } else {
                    throw 'error'
                }
            }
            catch {
                solution.value = 'fails'
            }
console.log(solution.value)            
            session.value = {id:null, docSessionRef: null}
            addDoc(collection(db, 'session'), {
                solution: solution.value,
                started: dbTypeTimeStamp.now(),
                state: "pending", 
                //uid: user.value.uid,
                uid: null,
                wordid: docWordObj.id
            }).then((docSessionRef) => {
                session.value = {id:docSessionRef.id, docSessionRef: docSessionRef} 
            })
            logEvent(GA, 'GAME_started', {} )
            gameStart.value = Date.now()
            elapsedTime.value = {ms: 0, human: '00:00'}
            setTimeout(runTimer, 500)
            gameOver.value = false
            restartConfirm.value = false
            boardSolved.value = false
        }
        const runTimer = () => {
            elapsedTime.value.ms = Date.now() - gameStart.value
            // 1- Convert to seconds:
            let seconds = elapsedTime.value.ms / 1000;
            // 2- Extract hours:
            const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
            seconds = seconds % 3600; // seconds remaining after extracting hours
            // 3- Extract minutes:
            const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
            // 4- Keep only seconds not extracted to minutes:
            seconds = seconds % 60;
            elapsedTime.value.human = ''
            if(hours>0) {
                elapsedTime.value.human = hours.toString() + ':'
            }
            elapsedTime.value.human = elapsedTime.value.human + (minutes < 10 ? '0' : '') + minutes.toString() + ':'
            elapsedTime.value.human = elapsedTime.value.human + (seconds < 10 ? '0' : '') + parseInt(seconds).toString()
            if(gameOver.value!==true && boardSolved.value!==true) {
                setTimeout(runTimer, 500)
            }
        }
        const clearBoard = () => {
            gameOver.value = false 
            boardSolved.value = false
            restartConfirm.value = false
            boardTiles.value = []
            for(let y=0;y<6;y++) {
                for(let x=0;x<5;x++) {
                    boardTiles.value.push({ row: y, col: x, value: '', status: 'unknown'  })
                }
            }
            cursor.value = {x:0,y:0}
        }
        onMounted(() => {
            logEvent(GA, 'onMounted', {})
            clearBoard()
            browserSupportshare.value = ( typeof navigator.share !== "undefined" ? true : false )        
        })
        const resetKeyboard = () => {
            keyboard.value.forEach(kbline => {
                kbline.forEach(k => {
                    if ( k.text == 'GO' ) {
                        k.status = 'unknown bg-primary text-white' 
                    }
                    else {
                        k.status = 'unknown' 
                    }
                })
            })
        }
        const markKey = (key,style) => {
            let keyfound = false
            keyboard.value.forEach(kbline => {
                if(keyfound===true) {
                    return
                }
                kbline.forEach(k => {
                    if(k.text==key) {
                        if ( k.status != 'correct' && ( style == 'invalid' || style == 'misplaced' || style == 'correct' ) ) {
                            k.status = style 
                        } 
                        keyfound = true 
                        return
                    }
                })
            })
        }
        const displayHelp = () => {
            logEvent(GA, 'displayHelp', {})
            showHelpFlag.value = true
            helpTip.value = 1
        }
        const restartGame = () => {
            if(boardSolved.value===true || gameOver.value===true || restartConfirm.value==true || welcomeMode.value==true ) {
                logEvent(GA, 'restartGame', {confirmed: true} )
                welcomeMode.value = false
                solution.value = null
                session.value = {id:null, docSessionRef: null}
                clearBoard()
                resetKeyboard()
                loadRandomWord()
            }
            else {
                logEvent(GA, 'restartGame', {confirmed: false} )
                restartConfirm.value = true 
            } 
        }
        const showShareOptions = async () => {
            if(navigator.share) {
                let canvasResultImage = document.getElementById('resultImage')
                const dataUrl = canvasResultImage.toDataURL()
                const blob = await (await fetch(dataUrl)).blob()
                const filesArray = [
                    new File(
                        [blob],
                        'wordleresult.png',
                        {
                        type: blob.type,
                        lastModified: new Date().getTime()
                        }
                    )
                ]        
                const shareData = {
                    files: filesArray,
                }
                if (navigator.canShare && navigator.canShare(shareData)) {
                    navigator.share(shareData)
                }
            }
        }
        const handleShare = () => {
            let objResult = []
            for(let y=0;y<6;y++) {
                let tilesrow = []
                for(let x=0;x<5;x++) {
                    let nTile = boardTiles.value.find((tile) => (tile.row == y && tile.col == x  ) )
                    if(typeof nTile !== undefined) {
                        tilesrow.push({status: nTile.status})
                    }
                    else {
                        tilesrow.push({status: "unknown"})
                    }                        
                }
                objResult.push(tilesrow)
            }
            let tileDimension = 256
            let padding = 48
            let gutter = 4
            let canvasResultImage = document.getElementById('resultImage')
            canvasResultImage.width = ((tileDimension*5) + ((5+1)*gutter)) + (padding*2)
            canvasResultImage.height = ((tileDimension*6) + ((6+1)*gutter)) + (padding*2)
            let canvasContext = canvasResultImage.getContext("2d")
            canvasContext.fillStyle = '#eceff1';
            canvasContext.fillRect(0, 0, canvasResultImage.width, canvasResultImage.height);
            for(let y=0;y<6;y++) {
                for(let x=0;x<5;x++) {
                    let tile = {status: "blank"}
                    if (y < objResult.length && x < objResult[y].length) {
                        tile = objResult[y][x]
                    }
                    if ( tile.status == "correct" ) {
                        canvasContext.fillStyle = '#00695c'; // green tile
                    }
                    else if ( tile.status == "misplaced" ) {
                        canvasContext.fillStyle = '#ffee58'; //yellow tile
                    }
                    else if ( tile.status == "invalid" ) {
                        canvasContext.fillStyle = '#3a3a3c'; //grey tile
                    }
                    else {
                        canvasContext.fillStyle = '#b0bec5'; //light grey unknown tile
                    }               
                    canvasContext.globalAlpha = 1.0
                    canvasContext.fillRect((x*tileDimension)+((x+1)*gutter)+x+padding-1, (y*tileDimension)+((y+1)*gutter)+y+padding-1, tileDimension, tileDimension);
                }
            }
            showShareOptions()
        }
        const handleKey = async (k) => {
            if(solution.value===null) {
                //random word not yet selected
                return
            }            
            if(document.querySelector(".tile-notaword")!=null) {
                //we are animating an invalid word - ignore this tap till the animation is complete
                return
            }
            if(cursor.value.y>5) {
                //exceeded number of tries - cannot continue
                return 
            }
            if(processingKey.value==true) { 
                return 
            }
            processingKey.value = true 
            if(k=='GO') {
                const rowTiles = ref([])
                let nBlankTiles = 0
                let guessWord = ''
                let guessWordResultNotation = '' // Y=Green, N=Invalid, M=Misplaced
                let guessWordResultObj = {correct: 0, misplaced: 0, incorrect: 0}
                for(let i=0; i<5; i++) {
                    let nTile = boardTiles.value.find((tile) => (tile.row == cursor.value.y && tile.col == i  ) )
                    rowTiles.value.push(nTile)
                    guessWord = guessWord + nTile.value
                    if (nTile.value == '' ) {
                        nBlankTiles = 1
                        break;
                    }
                }
                if(nBlankTiles===0) {         
                    const queryWords = query(wordsRef, where('word', '==', guessWord.toLowerCase()), limit(1))
                    const docSnap = await getDocs(queryWords)
                    if(docSnap.docs.length) {
                        let unsolvedonly = ''
                        rowTiles.value.forEach(tile => {   
                            unsolvedonly = unsolvedonly + (solution.value.toLowerCase().charAt(tile.col) == tile.value.toLowerCase()?'_':solution.value.toLowerCase().charAt(tile.col))
                        })                         
                        let solvedTileCount = 0
                        rowTiles.value.forEach(tile => {
                            let matchpos = -1 
                            if (solution.value.toLowerCase().charAt(tile.col) == tile.value.toLowerCase()) {
                                matchpos = tile.col //this is a solved tile
                            }
                            else {
                                matchpos = unsolvedonly.toLowerCase().indexOf(tile.value.toLowerCase())
                            }
                            if ( matchpos == -1 ) {
                                tile.status = 'invalid'
                                guessWordResultNotation = guessWordResultNotation + 'N'
                                guessWordResultObj.incorrect++ 
                                markKey(tile.value.toUpperCase(), tile.status)
                            } 
                            else if ( matchpos != tile.col) {
                                tile.status = 'misplaced'
                                guessWordResultObj.misplaced++
                                guessWordResultNotation = guessWordResultNotation + 'M'
                                markKey(tile.value.toUpperCase(), tile.status)
                            }
                            else if ( matchpos == tile.col) {
                                solvedTileCount++;
                                tile.status = 'correct'
                                guessWordResultObj.correct++
                                guessWordResultNotation = guessWordResultNotation + 'Y'
                                markKey(tile.value.toUpperCase(), tile.status)
                             }
                        })
                        if(typeof session.value.docSessionRef !== undefined) {
                            let attemptkey = "attempts.attempt_"+cursor.value.y
                            let attemptkeyresult = attemptkey+"_result"
                            let attemptkeytime = attemptkey+"_timedeltams"
                            updateDoc(session.value.docSessionRef, {
                                "no_of_attempts": cursor.value.y,
                                [attemptkey] : guessWord,
                                [attemptkeyresult] : guessWordResultNotation,
                                [attemptkeytime] : elapsedTime.value.ms,
                                state: (solvedTileCount==5?"solved":(cursor.value.y==5?"unsolved":"pending")),
                                "last_attempt_time_delta": elapsedTime.value.ms,
                                "updatetime": dbTypeTimeStamp.now()
                            })
                        }
                        /* logEvent(GA, 'GAME', {
                            solved: (solvedTileCount==5?true:false),
                            unsolved: (solvedTileCount==5?false:(cursor.value.y==5?true:false)),
                            inprogress: (solvedTileCount==5?false:(cursor.value.y==5?false:true)),
                            attempts: cursor.value.y,
                            currentguess: guessWord,
                            gameduration: elapsedTime.value.ms,
                            correctletters: guessWordResultObj.correct,
                            misplacedletters: guessWordResultObj.misplaced,
                            incorrectletters: guessWordResultObj.incorrect
                        }) */
                        logEvent(GA, 'GAME_attempt', {} )
                        logEvent(GA, 'GAME_attempt_' + (cursor.value.y+1).toString(), {} )
                        if(solvedTileCount==5) {
                            //game completed
                            boardSolved.value = true
                            session.value = {id:null, docSessionRef: null}
                            for(let i=0; i<5; i++) {
                                let nTile = boardTiles.value.find((tile) => (tile.row == cursor.value.y && tile.col == i  ) )
                                nTile.url = "https://www.google.com/search?q=define:" + solution.value
                            }
                            logEvent(GA, 'GAME_solved', {} )
                        }
                        else {
                            if(cursor.value.y==5) {
                                //game over
                                gameOver.value = true 
                                session.value = {id:null, docSessionRef: null}
                                logEvent(GA, 'GAME_unsolved', {} )
                            }
                            cursor.value = {x: 0, y: cursor.value.y+1}
                        }
                    } 
                    else {
                        rowTiles.value.forEach(tile => {
                            tile.status = 'notaword'
                            setTimeout(() => {
                                tile.status = 'unknown'
                            }, 300)
                        })
                    }                    
                }
            }
            else if(k=='BACKSPACE') {
                let firstNonBlankTileinRow = null
                for(let i=4; i>=0; i--) {
                    firstNonBlankTileinRow = boardTiles.value.find((tile) => (tile.row == cursor.value.y && tile.col == i && tile.value != '' ) )
                    if (typeof firstNonBlankTileinRow !== 'undefined' ) {
                        break;
                    }
                }
                if(typeof firstNonBlankTileinRow !== 'undefined' && firstNonBlankTileinRow != null) {
                    firstNonBlankTileinRow.value = ''
                    firstNonBlankTileinRow.status = 'unknown'
                    cursor.value.x = firstNonBlankTileinRow.col
                }
            } 
            else {
                if(cursor.value.x<5)  {
                    const currentTile = boardTiles.value.find((tile) => (tile.row == cursor.value.y && tile.col == cursor.value.x) )
                    currentTile.value = k
                    currentTile.status = 'unknown'
                    cursor.value.x++
                }
            }
            processingKey.value = false 
        }
        const changeColorMode = () => {
            logEvent(GA, 'changeColorMode', {} )
            if(document.body.classList.contains('light')) {
                document.body.classList.remove('light') 
                document.body.classList.add('dark') 
            } else {
                document.body.classList.remove('dark') 
                document.body.classList.add('light') 
            }
        }
        return { boardTiles, cursor, handleKey, keyboard, restartGame, boardSolved, restartConfirm, 
                        elapsedTime, gameOver, showHelpFlag, displayHelp, helpTip, changeColorMode, 
                        solution, welcomeMode, handleShare, browserSupportshare, showSettings, currentDifficultyLevel }
    }
}
</script>

<style>
.board, .keyboard, .navbar {
  /*max-width: 540px;*/
  /*width: 360px;*/
  width: 100%;
  max-width: 480px;
}

.dark .navbar {
    color: white;
}

.light .navbar {
    color: black;
}

.dark svg.bi-brightness-high-fill {
    display: none;
}
.light svg.bi-brightness-high {
    display: none;
}

    div[class*="tile-"] {
        font-size: 1.76rem;
        font-weight: 700;
    }

    .dark div[class*="tile-"] {
    border: solid 1px #D7CCC8 ;
    }

    .light div[class*="tile-"] {
    border: solid 1px black ;
    }


    div[class*="-correct"] {
        color: white;
        background-color: #00695C;
    }

    div[class*="-misplaced"] {
        color: black;
        background-color: #FFEE58;
    }

    div[class*="-invalid"] {
        color: white;
        /*background-color: #FF5722;*/
        background-color: #3a3a3c;
    }

    div[class*="-notaword"] {
        color: white;
        background-color: red;
    }


    div[class*="-unknown"] {
        color: black;
        background-color: #B0BEC5;
    }

    .tile-unknown, .tile-notaword {
    font-style: italic;
    }

    .tile-notaword {
        animation: .8s shake;
    }

    @keyframes shake {
        0% { transform: skewX(-10deg); }
        5% { transform: skewX(10deg); }
        10% { transform: skewX(-10deg); }
        15% { transform: skewX(10deg); }
        20% { transform: skewX(0deg); }
        100% { transform: skewX(0deg); }  
    }
     
    div[class*="key-"] {
        font-size: 1rem;
    }

    .keyboard li a {
        cursor: pointer;
    }

    .keyboard #keyboard-key-data-ENTER {
        text-transform: lowercase;
    }

    .keyboard li.col a div {
        min-height: 44px;
    }

    .container ul li div a {
        text-decoration: none;        
    }    

</style>