<template>
  <router-view />  
</template>

<script>
import { onMounted } from 'vue'
export default {
  setup() {
    onMounted(() => {
      if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.body.classList.add('dark') 
      }
      else {
        document.body.classList.add('light') 
      }
    })
  }
}
</script>

<style>
#app {
  display:flex; 
  flex-direction:column; 
  justify-content:center;
  min-width: 362px;
  /*font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;*/
}

body.light {
    background-color: #ECEFF1;
}

body.dark {
    background-color: #263238;
}

</style>
